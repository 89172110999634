<template>
    <div class="text-center" style="height: 500px; display: flex; align-items: center; justify-content: center;">
        <img alt="Logo Home Left" src="../assets/logo-home-left.png" class="m-5">
        <img alt="Logo Home Right" src="../assets/logo-home-right.png" class="m-5">
    </div>
</template>

<script>
import { homeUrl } from '@routes';
// import * as constants from "@constants";

export default {
    data() {
        return {
            breadcrumb: {
                title: 'Home',
                path: []
            }
        };
    },
    mounted() {
        this.$emit('update-breadcrumb', this.breadcrumb);

        //TODO This request is used to verify is token is valid.
        this.axios.get(homeUrl()).then(() => {}).catch(error => {
            console.error(error);
        });
    }
};
</script>
